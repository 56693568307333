"use client";

import { useEffect, useRef, useState } from "react";
import * as styles from "./styles.css";
import Input from "@/components/input";
import Button from "@/components/button";
import { usePathname } from "next/navigation";
import Icon from "@/elements/icons";

interface SignupFormProps {
  newsletterSignupHeading: string;
  newsletterSignupEmail: boolean;
  newsletterSignupEmailLabel: string;
  newsletterSignupEmailPlaceholder: string;
  newsletterSignupEmailRequired: boolean;
  newsletterSignupName: boolean;
  newsletterSignupNameLabel: string;
  newsletterSignupNamePlaceholder: string;
  newsletterSignupNameRequired: boolean;
  newsletterSignupGender: boolean;
  newsletterSignupGenderLabel: string;
  newsletterSignupGenderOptions: any;
  newsletterSignupGenderRequired: boolean;
  newsletterSignupPostcode: boolean;
  newsletterSignupPostcodeLabel: string;
  newsletterSignupPostcodePlaceholder: string;
  newsletterSignupPostcodeRequired: boolean;
  newsletterSignupSubmissionHeading: string;
  newsletterSignupSubmissionMessage: string;
}

export default function SignupForm(props: SignupFormProps) {
  const signupForm = useRef<any>();
  const [submitted, setSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const pathname = usePathname();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorMsg('');

    const formData = new FormData(e.target);
    const formDataObject = Object.fromEntries(formData.entries());
    const campaignMonitorData = {
      EmailAddress: formDataObject[props.newsletterSignupEmailLabel],
      Name: formDataObject[props.newsletterSignupNameLabel],
      CustomFields: [
        {
          Key: "Gender",
          Value: formDataObject[props.newsletterSignupGenderLabel],
        },
        {
          Key: "Postcode",
          Value: formDataObject[props.newsletterSignupPostcodeLabel],
        },
      ],
      ConsentToTrack: "Unchanged",
    };

    try {
      const response = await fetch("/api/campaign-monitor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(campaignMonitorData),
      });

      if (!response.ok) {
        setErrorMsg("Failed to submit. Please try again");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setSubmitted(true);
      setErrorMsg('');
    } catch (error) {
      console.error("Error during fetch:", error);
      setErrorMsg("Failed to submit. Please verify that your details are correct.");
      // alert("Failed to submit. Please verify that your details are correct.");
    }
  };

  const resetForm = () => {
    setSubmitted(false);
    setErrorMsg('');
  };

  useEffect(() => {
    if (pathname && signupForm.current) {
      signupForm.current.reset();
      resetForm();
    }

    if (submitted) {
      resetForm();
    }
  }, [pathname]);

  return (
    <div className={styles.formContainer}>
      {!submitted && (
        <>
          <h2 className={styles.formHeading}>
            {props.newsletterSignupHeading}
          </h2>
          <form ref={signupForm} onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formInputs}>
              {props.newsletterSignupEmail && (
                <Input
                  type="email"
                  label={props.newsletterSignupEmailLabel}
                  placeholder={props.newsletterSignupEmailPlaceholder}
                  required={props.newsletterSignupEmailRequired}
                  className={styles.formInputLarge}
                />
              )}
              {props.newsletterSignupName && (
                <Input
                  type="text"
                  label={props.newsletterSignupNameLabel}
                  placeholder={props.newsletterSignupNamePlaceholder}
                  required={props.newsletterSignupNameRequired}
                  className={styles.formInputLarge}
                />
              )}
              {props.newsletterSignupGender && (
                <Input
                  type="select"
                  label={props.newsletterSignupGenderLabel}
                  required={props.newsletterSignupGenderRequired}
                  className={styles.formInputSmall}
                >
                  <option value="">Please select</option>
                  {props.newsletterSignupGenderOptions.map(
                    (option: any, index: number) => (
                      <option key={index} value={option.option}>
                        {option.option}
                      </option>
                    )
                  )}
                </Input>
              )}
              {props.newsletterSignupPostcode && (
                <Input
                  type="text"
                  label={props.newsletterSignupPostcodeLabel}
                  placeholder={props.newsletterSignupPostcodePlaceholder}
                  required={props.newsletterSignupPostcodeRequired}
                  className={styles.formInputSmall}
                />
              )}
            </div>
            <Button
              style="primary"
              label="Sign up"
              type="submit"
              className={styles.formButton}
            />
            {errorMsg && 
              <div className={styles.error}>{errorMsg}</div>
            }
          </form>
        </>
      )}
      {submitted && (
      <div className={styles.submitted}>
        <div className={styles.submittedIcon}>
          <Icon type="check-circle-filled"></Icon>
        </div>
        <div className={styles.submittedContent}>
          <h2 className={styles.formHeading}>
            {props.newsletterSignupSubmissionHeading}
          </h2>
          <p className={styles.submissionText}>
            {props.newsletterSignupSubmissionMessage}
          </p>
        </div>
      </div>
      )}
    </div>
  );
}
