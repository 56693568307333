import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22modules%2Ffooter%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8VWyW7bMBC9%2BysGyCVBSleSZTtRLi3S5jcCWhxJbClSJRkvLfLvhTZHq7cCrg%2BGRc%2F63psRp68u%2B2XWrnXgzwQgpTrmkqyUtSoNwF1k26cJQII8TmwA82X%2B%2FD6Z1l5u4VX%2FzWWCmtuWhVdYREpaEtGUi10Aa6pvCSmOqFihtsRQae6eajvDf2MAzvRhOdeY5seCSyR1Fnfq7c%2FRWtTEZDTkMg7AyQ9DJZQO4Oal%2BLSKmV25mEZqv0idUca4jIlV2Qe6%2FxOcKuqmcl847arnV2dvEKEzq140tVwE8vwyEOMmE3QXQCSwxF7gljCuMbRcyQC02uTHVPBYEm4xNQGEKC3q%2FPjHm7E82pFQSYvSlmGIsVS3Rb%2B8FLYmOm3UlgdR68NxcA4eLqd1jFX3XN016WlNQt2G32H1cXxDNaxoYSW4scTYncAApJI4nLDhtrq60DuNPHQKCouCjuo1VOItlUTjGrXBQY3maZGs0G4QZU%2FcTQUP0RRrzkiZpQglwfXgc%2F3r4vV1oYxiOkQeuzp5B9cPDlM3slMiLsTwlGIglb0NBDWWhAkX7O6kCYgKq%2BO87XstZ2%2BlNENd9Vc%2BVLOSbcEowRncuI7%2F7H3tDpPvlBE6hc28Hk1vYnQ6T1T6%2BBZGyfrZBIf7%2FKv7PuhPWwb3kPXs%2FI5dDFMeKvn6muemXKIuXDac2SQAz2lfl%2BrncsYSzeXP7rUAewE%2FQeM9RpsV6YqvLuFxZWY1lYbv0VIanKlrAGm5GFqaXSnBukGCRK2rhj72wOPjzB8xNOu4HLxawQO2HZvvz4sX79vTsWLfJ19SZJyCCTWiBCoZ3KZ0SyqgXcebZdtyHDo3zVOU%2F9508092a0ujlHcn2Pyfgw23zmWjdf%2BM1v06q39G4wecxhs84BRWTiOXrdHVWO37QaT3y2Q0bN%2B8uQ3a%2BDstSgRGtsXJX3fW6xAmDQAA%22%7D"
export var contactContainer = '_1dqsv1t5';
export var contactItem = '_1dqsv1te';
export var contactMenuContainer = '_1dqsv1t9';
export var description = '_1dqsv1t3';
export var footer = '_1dqsv1tg';
export var footerHr = '_1dqsv1tf';
export var footerSecondary = '_1dqsv1tc';
export var footerSecondaryLinks = '_1dqsv1td';
export var logo = '_1dqsv1t0';
export var logoContainer = '_1dqsv1t2';
export var logoLink = '_1dqsv1t1';
export var menuContainer = '_1dqsv1t4';
export var menuItem = '_1dqsv1tb';
export var menuItemsContainer = '_1dqsv1ta';
export var menuSubtitle = '_1dqsv1t8';
export var menuTitle = '_1dqsv1t7';
export var socialContainer = '_1dqsv1t6';