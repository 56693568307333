"use client";

import * as styles from "./styles.css";
import { PortableText } from "@portabletext/react";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import Link from "next/link";
import Button from "@/components/button";
import { HeaderAnnouncement, MenuSettings } from "@/types/sanity.types";
import Logo from "@/components/logo";
import Icon from "@/elements/icons";
import NavCampaign from "@/components/nav-campaign";
import { ptComponents } from "@/utils/portableTextComp";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";

type MenuItem = {
  main: {
    slug: string
    title: string
    _type: string
  }
  sub?: [
    {
      buttonLink: string
      title: string  
      _type: string
    }
  ]
}

interface NavigationBarProps {
  announcement?: HeaderAnnouncement;
  menuSettings?: MenuSettings;
  menuItems: {
    [key: string]: MenuItem;
  };
  logo: { 
    imageSource: string;
    imageAlt?: string;
    width: number;
    height: number
  }
}

export default function NavigationBar(props: NavigationBarProps) {
  const menuData = props.menuItems ? Object.keys(props.menuItems) : [];
  const [activeSubNav, setActiveSubNav] = useState<number>(-1);
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(true);
  const [mobileMainMenuState, setMobileMainMenuState] = useState("is--visible");
  const [mobileSubMenuState, setMobileSubMenuState] = useState("is--hidden");
  const [resetState, setResetState] = useState(false);
  const pathname = usePathname();

  const subnavHandler = (index: number) => {
    if (activeSubNav === index) {
      setActiveSubNav(-1);
    } else {
      setActiveSubNav(index);
    }
  }
  const mobileSubnavHandler = (index: number) => {
    if (mobileMainMenuState === 'is--visible' && index !== -1) {
      subnavHandler(index);
      setMobileMainMenuState('is--exit');
      setMobileSubMenuState('is--exit');
      setTimeout(() => {
        setMobileSubMenuState('is--visible');
        setMobileMainMenuState('is--hidden');
      }, 200);
    }
    
    if (index === -1) {
      subnavHandler(-1);
      setMobileSubMenuState('is--exit');
      
      setTimeout(() => {
        setMobileSubMenuState('is--hidden');
        setMobileMainMenuState('is--exit');

        setTimeout(() => {
          setMobileMainMenuState('is--visible');
        }, 100);
      }, 200);
    }
  }
  const resetMobileNav = () => {
    setActiveSubNav(-1);
    setMobileMainMenuState('is--hidden');
    setMobileSubMenuState('is--hidden');
  }

  useEffect(() => {
    if (props?.announcement?.enableAnnouncement) {
      setShowAnnouncement(true)
    } else {
      setShowAnnouncement(false)
    }
  }, []);

  useEffect(() => {
    if (openMobileMenu) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.removeAttribute('style');
    }
  }, [openMobileMenu]);

  useEffect(() => {
    if (openMobileMenu && pathname) {
      mobileMenuHandler();
      resetMobileNav();
    }

    if (window.innerWidth >= 1280 && !resetState) {
      setResetState(true);
    }
  }, [pathname]);

  const mobileMenuHandler = () => {
    if (openMobileMenu) {
      resetMobileNav()
    }

    setOpenMobileMenu(!openMobileMenu); 

    if (mobileMainMenuState === 'is--hidden') {
      setTimeout(() => {
        setMobileMainMenuState('is--visible')
      }, 200);
    }
  }

  return (
    <PageSection layout="navigation" backgroundColor="background-darken-3">
      {props?.announcement?.enableAnnouncement && 
        <div className={`${styles.announcement} ${!showAnnouncement ? 'is--hidden' : ''}`}>
          {props?.announcement?.bodyText && (
            <PortableText value={props?.announcement?.bodyText} components={ptComponents} />
          )}
          {props?.announcement?.button?.buttonLabel && 
            <div className={styles.announcementButton}>
              <Button 
                size="small" 
                href={props.announcement.button?.buttonLink || ""}
                label={props.announcement.button?.buttonLabel || ""}
                style="tertiary"
                target={props.announcement.button?.linkType === 'external link' ? '_blank' : ''}
                linkType={props.announcement?.button?.linkType}
              />
              <button className={styles.announcementClose} onClick={() => setShowAnnouncement(false)}>
                Close
                <div className={styles.closeIcon}>
                  <Icon type="x-filled" />
                </div>
              </button>
            </div>
          }
        </div>
      }
      <nav className={`${styles.nav}${openMobileMenu ? ' is--active' : ''}`}>
        <ContentContainer layout="navigation" className={styles.navContainer}>
          <div className={styles.logo}>
            <Link href="/" prefetch={false}>
              {props?.logo.imageSource && 
                <Logo imageSource={props.logo.imageSource} imageAlt={props.logo.imageAlt} width={Math.ceil(props.logo.width / 2)} height={Math.ceil(props.logo.height / 2)} />
              }
            </Link>
          </div>
          <button className={styles.buttonMenu} onClick={() => mobileMenuHandler()}>
            {!openMobileMenu &&
              <Icon type="menu-alt-3-unfilled" />
            }
            {openMobileMenu &&
              <Icon type="x-filled" />
            }
          </button>
          <div className={`${styles.navItemContainer} is--desktop`}>
            {menuData.length > 0 && props.menuItems[menuData[0]].main && (
              <ul className={styles.navItemContainerList}>
                {menuData.map((item, index) => (
                  <li 
                    key={`nav-${props.menuItems[item]?.main.slug}-main`}
                    className={`${styles.navItemContainerListItem} ${props.menuItems[item]?.sub ? 'has--subitem' : ''}${activeSubNav === index ? ' is--active' : ''}`}
                    onMouseOver={() => { if (activeSubNav !== -1) { subnavHandler(index)} setResetState(false)}}>
                    <Link href={`/${props?.menuItems[item]?.main?.slug}`} className={`${styles.navItem}${pathname.includes(props.menuItems[item].main.slug) ? ' is--active' : ''}`} prefetch={false}>
                      {props.menuItems[item].main.title}

                      {props.menuItems[item]?.sub && 
                        <button className={styles.navIcon} onClick={(e) => { e.preventDefault(); subnavHandler(index)}}>
                          <Icon type="chevron-down-filled" />
                          <Icon type="chevron-up-filled" />
                        </button>
                      }
                    </Link>
                    {props.menuItems[item]?.sub && props.menuItems[item]?.sub.length > 0 && (
                      <div className={`${styles.submenu}${resetState ? ' reset' : ''}`} id={`${resetState ? 'submenu-reset' : ''}`}>
                        <ContentContainer layout="subnav">
                          {props.menuSettings?.campaignEnable && 
                            <NavCampaign title={props.menuSettings?.campaignTitle || ''} image={props.menuSettings?.campaignImage} button={props.menuSettings?.campaignButton} />
                          }
                          <div />
                          <div className={styles.submenuItemContainer}>
                            <Link className={styles.submenuHeading} href={`/${props?.menuItems[item]?.main?.slug}`} prefetch={false}>
                              <h2>{props.menuItems[item].main.title}</h2>
                              <div className={styles.icon}>
                                <Icon type="arrow-sm-right-filled" />
                              </div>
                            </Link>
                            <ul>
                                {props.menuItems[item].sub.map((subitem) => (
                                  <li key={`subnav-${subitem.buttonLink}`}>
                                    <Link href={subitem?.buttonLink ?? '/'} className={`${styles.navSubItem}${subitem.buttonLink === pathname ? ' is--active' : ''}`} target={subitem?.buttonLink?.includes('http') ? '_blank' : '_self'} prefetch={false}>{subitem.title}</Link>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </ContentContainer>
                      </div>
                    )}
                  </li>
                ))}
                <li className={styles.navItemContainerListItem}>
                  <Link className={styles.searchButtonMenu} href="/search" prefetch={false}>
                    <div className={styles.searchIcon}>
                      <Icon type="search-unfilled" />
                    </div>
                    Search
                  </Link>
                </li>
                {(props.menuSettings?.ctaButtons && props.menuSettings?.ctaButtons.length > 0) &&
                  props.menuSettings.ctaButtons.map((ctaItem, index) => (
                    <li key={`ctaDesktop-${ctaItem._key}`} className={`${styles.navItemContainerListItem}${index === 0 ? ' is--cta-first' : ' is--cta-last'}`}>
                      <Button
                        href={ctaItem.buttonLink ?? "/"}
                        label={ctaItem?.buttonLabel ?? ''}
                        target={ctaItem.linkType === 'external link' ? '_blank' : ''}
                        style={index === 0 ? 'secondaryInvert' : 'primary'}
                        size="small"
                        linkType={ctaItem.linkType}
                        rollerProductID={ctaItem.rollerID}
                      />
                    </li>
                  )) 
                }
              </ul>
            )}
          </div>

          <div className={`${styles.mobileContainer} is--mobile`}>
            <div className={styles.mobileContentContainer}>
              <div className={`${styles.mobileMainContent} ${mobileMainMenuState} ${showAnnouncement ? 'has--announcement' : ''}`}>
                {menuData.length > 0 && props.menuItems[menuData[0]].main && (
                  <ul className={`${styles.navItemContainerList}`}>
                    {menuData.map((item, index) => (
                      <li key={`nav-mobile-${props.menuItems[item]?.main.slug}`} className={styles.navItemContainerListItem}>
                        <button className={`${styles.mobileLink}${pathname.includes(props.menuItems[item].main.slug) ? ' is--active' : ''}`} onClick={() => mobileSubnavHandler(index)}>
                          {props.menuItems[item].main.title}

                          {props.menuItems[item]?.sub && 
                            <div className={styles.navIcon}>
                              <Icon type="chevron-right-filled" />
                            </div>
                          }
                        </button>
                      </li>
                    ))}
                    <li className={styles.navItemContainerListItem}>
                      <Link className={styles.searchButtonMenu} href="/search" prefetch={false}>
                        Search
                        <div className={styles.searchIcon}>
                          <Icon type="search-unfilled" />
                        </div>
                      </Link>
                    </li>
                  </ul>
                )}
                {props.menuSettings?.campaignEnable && 
                  <div className={`${styles.campaignSection} ${showAnnouncement ? 'has--announcement' : ''}`}>
                    <NavCampaign title={props.menuSettings?.campaignTitle || ''} image={props.menuSettings?.campaignImage} button={props.menuSettings?.campaignButton} />
                  </div>
                }
              </div>
              <div className={`${styles.mobileSubContent} ${mobileSubMenuState}`}>
                <button className={`${styles.mobileLink} is--mobile-sub`} onClick={() => mobileSubnavHandler(-1)}>
                  <div className={`${styles.navIcon} is--mobile-link`}>
                    <Icon type="chevron-left-filled" />
                  </div>
                  Back
                </button>
                {menuData.length > 0 && props.menuItems[menuData[0]].main && (
                  <ul className={`${styles.navItemContainerList}`}>
                    {menuData.map((item, index) => (
                      <li 
                        key={`subContentItem-${props.menuItems[item]?.main.slug}`} 
                        className={`${styles.mobileSubContentItem} ${activeSubNav === index ? 'is--visible' : 'is--hidden'}`}>
                        {props.menuItems[item]?.sub && props.menuItems[item]?.sub.length > 0 && (
                          <>
                            <Link 
                              className={styles.submenuMobileHeading} 
                              href={`/${props?.menuItems[item]?.main?.slug}`}
                              prefetch={false}>
                              <h2>{props.menuItems[item].main.title}</h2>
                              <div className={styles.icon}>
                                <Icon type="arrow-sm-right-filled" />
                              </div>
                            </Link>
                            <ul>
                                {props.menuItems[item].sub.map((subitem) => (
                                  <li key={`subnavMob-${subitem.buttonLink}`} className={styles.submenuMobileMenuItems}>
                                    <Link href={subitem?.buttonLink ?? '/'} className={`${pathname === subitem.buttonLink ? ' is--active' : ''}`} target={subitem?.buttonLink?.includes('http') ? '_blank' : '_self'} prefetch={false}>{subitem.title}</Link>
                                  </li>
                                ))}
                            </ul>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </ContentContainer>
        {(props?.menuSettings?.ctaButtons && props?.menuSettings?.ctaButtons.length > 0) &&
          <div className={`${styles.ctaButton} ${props?.menuSettings?.ctaButtons.length === 1 ? 'is--single' : ''}`}>
            { props?.menuSettings?.ctaButtons.map((ctaItem) => (
              <Link
                key={`cta-${ctaItem._key}`}
                href={ctaItem.buttonLink ?? "/"}
                target={ctaItem.linkType === 'external link' ? '_blank' : ''}
                prefetch={false}
              >
                { ctaItem?.buttonLabel ?? '' }
              </Link>
            ))} 
          </div>
        }
      </nav>
    </PageSection>
  );
}
