import * as styles from "./styles.css";
import Image from "next/image";
import ButtonContainer from "@/components/button-container";
import Button from "@/components/button";
import Link from "next/link";

interface Props {
  title?: string;
  image?: any;
  button?: any;
}

export default function NavCampaign(props: Props) {
  return (
    <div
      className={`${styles.submenuCampaign}${props?.image ? " has--image" : ""}`}
    >
      <Link href={props?.button.buttonLink} prefetch={false}>
        {props?.image && (
          <div className="campaign-image__container">
            <Image
              src={`${props.image?.url}?h=280&q=90&auto=format`}
              alt={props.image?.imageAlt ?? ""}
              width={props.image?.width}
              height={props.image?.height}
              priority={false}
              placeholder="blur"
              blurDataURL={`${props.image?.url}?w=10&auto=format`}
              quality={80}
              data-nosnippet
            />
          </div>
        )}
        <div className={styles.contentContainer}>
          {props.title && (
            <div className={styles.campaignTitle}>{props.title}</div>
          )}
          {props.button?.buttonVisible && (
            <ButtonContainer layout="align-left">
              <Button
                label={props?.button?.buttonLabel || ""}
                style="tertiary"
                size="large"
                target={
                  props.button.linkType === "external link" ? "_blank" : ""
                }
                linkType={props.button.linkType}
                rollerProductID={props?.button?.rollerID}
              />
            </ButtonContainer>
          )}
        </div>
      </Link>
    </div>
  );
}
