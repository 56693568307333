import * as styles from './styles.css';
import Image from "next/image";

interface LogoProps {
  imageSource: string;
  imageAlt?: string;
  width: number;
  height: number;
}

export default function Logo(props: LogoProps) {
  return (
    <div className={styles.container}>
      {props.imageSource && (
        <Image
          src={props.imageSource}
          alt={props?.imageAlt ?? ""}
          width={props.width}
          height={props.height}
          priority={true}
          placeholder="blur"
          blurDataURL={props.imageSource}
        />
      )}
    </div>
  ) 
}