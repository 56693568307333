"use client";

import { useDraftModeEnvironment } from "next-sanity/hooks";

export function DisableDraftMode() {
  const environment = useDraftModeEnvironment();

  // Only show the disable draft mode button when outside of Presentation Tool
  if (environment !== "live" && environment !== "unknown") {
    return null;
  }

  return (
    <a
      href="/api/draft-mode/disable"
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: "black",
        color: "white",
        padding: 8,
        zIndex: 999,
      }}
    >
      Disable Draft Mode
    </a>
  );
}
